import { Box, Typography, Card } from "@mui/material";
import React, { useState } from "react";
import "./registration.css";
import { useNavigate } from "react-router-dom";

const Registration = () => {
  const [relationship, setrelationship] = useState(" ");
  const [age, setage] = useState(" ");
  const [city, setcity] = useState(" ");
  const [phone, setphone] = useState(" ");
  const [email, setemail] = useState(" ");
  const history = useNavigate();
  const saveData = async () => {
    const response = await fetch("https://susral.vercel.app/api/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        relationship: relationship,
        age: age,
        city: city,
        phone: phone,
        email: email,
      }),
    });
    console.log(response);
    if (response.status === 200) {
      history("/success");
    } else {
      window.alert("Registration Unsuccessful");
      history("/Registration");
    }
  };
  return (
    <>
      <div className="registration1 bg1">
        <div className="reg-container1">
          <Card
            sx={{
              px: { xs: 6, md: 20 },
              py: 2,
              mt: 4,
              mx: 2,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: "700",
                color: "#96216b",
              }}
            >
              LAUNCHING SOON
            </Typography>
            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                color: "#000000",
              }}
            >
              Be the first to join... the only real Shaadi site of Pakistan!
              Connect with verified Rishtas on Susral.com
            </Typography>
          </Card>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: null,
          }}
        >
          <Card
            sx={{
              width: { xs: "100%", md: "35%" },
              px: 2,
              py: 4,
              mt: 4,
              mx: 2,
              color: "#96216b",
            }}
          >
            <div className="settingtop">
              <label htmlFor="seeking-rishta">I am seeking rishta for</label>

              <select
                value={relationship}
                onChange={(e) => {
                  setrelationship(e.target.value);
                }}
              >
                <option value=" ">Select</option>
                <option value="brother">Brother</option>
                <option value="son">Son</option>
                <option value="daughter">Daughter</option>
                <option value="sister">Sister</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="form-inputs">
              <div className="age">
                <label htmlFor="age">Age</label>
                <input
                  className="text-input"
                  type="number"
                  value={age}
                  onChange={(e) => {
                    setage(e.target.value);
                  }}
                />
              </div>

              <label htmlFor="city" className="labelPosCity">
                City
              </label>
              <input
                className="input-city "
                value={city}
                onChange={(e) => {
                  setcity(e.target.value);
                }}
              />
            </div>

            <div className="form-inputs">
              <label htmlFor="phone">Phone</label>
              <input
                value={phone}
                onChange={(e) => {
                  setphone(e.target.value);
                }}
              />
            </div>

            <div className="form-inputs">
              <label htmlFor="email">E-mail</label>
              <input
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </div>

            <button onClick={saveData} className="RegisterBtn">
              Register
            </button>
          </Card>
        </Box>
      </div>
    </>
  );
};

export default Registration;
