import React from "react";
import icon from "../../assets/web-logo.jpg";
import { Box, Typography, Card } from "@mui/material";
import styled from "@emotion/styled";

const Logo = styled("img")`
  width: 12rem;
  height: 6rem;
  padding: 1rem;
`;

function RegistrationCompleted() {
  return (
    <Box
      className="registration1 bg1"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        backgroundColor: "#F1F6FD",
        py: 0.5,
      }}
    >
      <Card
        sx={{
          py: 2,
          px: 4,
          width: { xs: "70%", md: "30%" },
        }}
      >
        <Logo src={icon} alt="icon" />
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: "700",
            color: "#96216b",
          }}
        >
          Congratulations! Registration  Successful!
        </Typography>

        <Typography
          sx={{
            mt: 2,
            mb: 4,
            color: "#000000",
          }}
        >
          We will reach out to you shortly.
        </Typography>
      </Card>
    </Box>
  );
}

export default RegistrationCompleted;
