import registration from "./components/registrationform/registration";
import Complete from "./components/registrationform/complete";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

function App() {
  var Registration = registration;
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="Registration" />} />
          <Route path="/Registration" element={<Registration />} />
          <Route path="/success" element={<Complete />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
